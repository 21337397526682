import React, { useEffect, useState } from "react"
import type { PropsWithStyle } from "@mallardbay/lib-react-components"
import { Image, useIsDarkMode } from "@mallardbay/lib-react-components"

interface Props extends PropsWithStyle {
    readonly lightModeSrc: string
    readonly darkModeSrc: string
    readonly alt?: string
}

export default function LightAndDarkModeImage({
    style,
    lightModeSrc,
    darkModeSrc,
    alt,
}: Props) {
    const isDarkMode = useIsDarkMode()
    const [src, setSrc] = useState(lightModeSrc)

    // Wait until client has finished rendering to set the logo src and prevent hydration mismatch
    useEffect(() => {
        setSrc(isDarkMode ? darkModeSrc : lightModeSrc)
    }, [isDarkMode])

    return <Image style={style} src={src} alt={alt} />
}
