import { useToast } from "~components/shared/todo-lib-react-components/hooks/use-toast"
import { useCreateDraftOnboardingMutation } from "~graphql/generated/graphql"

export default function useCreateDraftOnboarding({
    onSuccess,
}: {
    onSuccess: (id: string) => void
}) {
    const { showError } = useToast()

    const [createDraftOnboarding, { loading: isLoading, error }] =
        useCreateDraftOnboardingMutation({
            onError: showError,
            onCompleted: (data) => onSuccess(data.createDraftOnboarding.id),
        })

    return { createDraftOnboarding, isLoading, error }
}
