import type { PropsWithChildren } from "react"
import React, { useMemo } from "react"
import {
    Provider,
    useCoreThemeForProvider,
} from "@mallardbay/lib-react-components"
// TODO: Right now we need to use the ChakraProvider along with the Provider
// from lib-react-components, otherwise the toasts don't work. We can address
// this once we're done converting everything as a last step.
// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from "@chakra-ui/react"

export function ThemeProvider({ children }: Readonly<PropsWithChildren>) {
    const theme = useCoreThemeForProvider()

    const themeToUse = useMemo(() => {
        return {
            ...theme,
            ...getThemeGlobalStyles(),
        }
    }, [theme])

    return (
        <Provider theme={themeToUse}>
            <ChakraProvider theme={themeToUse}>{children}</ChakraProvider>
        </Provider>
    )
}

// !IMPORTANT styles applied here will affect wites where widgets are embedded
// These are setup so we can style builder.io components
// with the same styles as the rest of the site
// Use css vars so they account for color mode
// They matcj styles from our components so they should be in sync
function getThemeGlobalStyles() {
    const textColor = "var(--chakra-colors-chakra-body-text)  !important"
    const headingCommonProps = {
        color: textColor,
        fontFamily: "var(--chakra-fonts-heading)",
        fontWeight: "var(--chakra-fontWeights-bold)",
    }
    const builderBlocksTargetClass = ".builder-blocks"
    return {
        styles: {
            global: {
                [`${builderBlocksTargetClass} p`]: {
                    color: textColor,
                },
                [`${builderBlocksTargetClass} h1`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-3xl) !important",
                },
                [`${builderBlocksTargetClass} h2`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-2xl) !important",
                },
                [`${builderBlocksTargetClass} h3`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-xl) !important",
                },
                [`${builderBlocksTargetClass} h4`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-lg) !important",
                },
                [`${builderBlocksTargetClass} h5`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-md) !important",
                },
                [`${builderBlocksTargetClass} h6`]: {
                    ...headingCommonProps,
                    fontSize: "var(--chakra-fontSizes-sm) !important",
                },
            },
        },
    }
}
