import React from "react"
import type { ApolloError } from "@apollo/client"
import {
    ConditionalRenderContainer,
    type PropsWithChildrenAndStyle,
} from "@mallardbay/lib-react-components"

import Error from "~components/shared/error"
import Spinner from "~components/shared/todo-lib-react-components/spinner"
import { isString } from "~utils/helpers"

interface Props extends PropsWithChildrenAndStyle {
    readonly isLoading?: boolean
    readonly error?: ApolloError | Error | string | null
    readonly shouldRenderChildren: boolean
    readonly noMargin?: boolean
    readonly noPadding?: boolean
}

export default function LoadingOrErrorContainer({
    style,
    isLoading = false,
    error = null,
    shouldRenderChildren = false,
    noMargin = false,
    noPadding = false,
    children = null,
}: Props) {
    const errorMessage = isString(error) ? error : error?.message

    if (isLoading) return <Spinner noPadding={noPadding} />
    if (errorMessage) {
        return <Error errorMsg={errorMessage} noMargin={noMargin} />
    }

    return (
        <ConditionalRenderContainer
            style={style}
            shouldRender={shouldRenderChildren}
        >
            {children}
        </ConditionalRenderContainer>
    )
}
