import React from "react"
import { Link } from "gatsby-link"
import { componentStyles } from "@mallardbay/lib-react-components"

import TextLogoDark from "~images/logos/mb-logo-dark.svg"
import TextLogoLight from "~images/logos/mb-logo.svg"
import LightAndDarkModeImage from "~components/shared/light-and-dark-mode-image"
import COPY from "~config/copy-constants"
import Box from "~components/shared/todo-lib-react-components/box"

interface Props {
    readonly isCentered?: boolean
}

export default function TextLogo({ isCentered }: Props) {
    const styles = useStyles({ isCentered })

    return (
        <Box style={styles.root}>
            <Link to="/">
                <LightAndDarkModeImage
                    style={styles.image}
                    lightModeSrc={TextLogoLight}
                    darkModeSrc={TextLogoDark}
                    alt={COPY.MALLARD_BAY_LOGO}
                />
            </Link>
        </Box>
    )
}

function useStyles({ isCentered }: { isCentered?: boolean }) {
    return componentStyles({
        root: {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            justifyContent: isCentered ? "center" : "flex-start",
        },
        image: {
            width: 200,
        },
    })
}
