import { componentStyles, useColors } from "@mallardbay/lib-react-components"
import React from "react"

import useScript from "~utils/use-script"
import LoadingOrErrorContainer from "~components/shared/todo-lib-react-components/loading-or-error-container"
import { STRIPE_PRICING_TABLE_SCRIPT_URL } from "~config/constants"

interface Props {
    readonly pricingTableId: string | undefined
    readonly clientReferenceId: string
}

export default function StripePricingTable({
    pricingTableId,
    clientReferenceId,
}: Props) {
    const styles = useStyles()

    const { isLoaded: isScriptLoaded } = useScript(
        STRIPE_PRICING_TABLE_SCRIPT_URL
    )

    return (
        <LoadingOrErrorContainer
            style={styles.root}
            isLoading={!isScriptLoaded}
            shouldRenderChildren
        >
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={process.env.GATSBY_STRIPE_PUBLIC_KEY ?? ""}
                client-reference-id={clientReferenceId}
            />
        </LoadingOrErrorContainer>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        root: {
            width: "100vw",
            backgroundColor: colors.bgColor,
            // Prevents the iframe within from defaulting to light mode,
            // and instead uses the root background color of the page.
            colorScheme: "normal",
        },
    })
}
