export const SOCIAL_LINKS = {
    FACEBOOK: "https://www.facebook.com/Mallard-Bay-Outdoors-116624006387763",
    TWITTER: "https://twitter.com/mallardbay_",
    INSTAGRAM: "https://www.instagram.com/mallardbay_",
    YOUTUBE: "https://www.youtube.com/@mallardbayoutdoors8103",
    LINKEDIN: "https://www.linkedin.com/company/mallard-bay-outdoors-llc",
}

export const SUPPORT_CONTACTS = {
    PHONE: "+1 (225) 725-7543",
    EMAIL: "support@mallardbay.com",
}

export const SEARCH_PAGE_SIZE = 20

export const NUMBER_OF_RECENT_BOOKINGS = 12

export const AUTH_REDIRECT_ROUTE = "/auth-redirect"

export const GUIDE_TECH_URL = "https://mallardbay.com/for-guides"

export const RESOURCES_URL = "https://resources.mallardbay.com/"

const HEADER_HEIGHT = 80
const HEADER_BOTTOM_PADDING = 12
export const HEADER_DIMENSIONS = {
    HEIGHT: HEADER_HEIGHT,
    MOBILE_BOTTOM_PADDING: HEADER_BOTTOM_PADDING,
    HEIGHT_PX: `${HEADER_HEIGHT}px`,
    MOBILE_BOTTOM_PADDING_PX: `${HEADER_BOTTOM_PADDING}px`,
}

export const Z_INDICES = {
    FLOATING_BUTTON: 1000,
    MODAL: 2000,
    POPOVER: 3000,
    WIDGET_MODAL: 10_000,
}

export const VISIBILITY_STATES = {
    VISIBLE: "visible",
    HIDDEN: "hidden",
} as const

export const LISTING_IMAGE_PLACEHOLDER =
    "https://www.logistec.com/wp-content/uploads/2017/12/placeholder.png"

export const GROUP_PAY_MEMBER_PAYMENT_INFO_SECTION_ID =
    "group-member-join-payment-info"

export const CHECKOUT_STEPS = {
    DATES: "DATES",
    PACKAGE: "PACKAGES",
    GUESTS_AND_ADD_ONS: "GUESTS_AND_ADD_ONS",
    LODGING: "LODGING",
    SUMMARY: "SUMMARY",
    WIDGET_PACKAGE: "WIDGET_PACKAGE",
    WIDGET_INFO: "WIDGET_INFO",
    WIDGET_PAYMENT: "WIDGET_PAYMENT",
    WIDGET_CONFIRMATION: "WIDGET_CONFIRMATION",
}

export enum CustomHeaders {
    CLIENT_NAME = "x-client-name",
    CLIENT_VERSION = "x-client-version",
    UTM_CAMPAIGN = "x-utm-campaign",
    UTM_CONTENT = "x-utm-content",
    UTM_SOURCE = "x-utm-source",
    UTM_MEDIUM = "x-utm-medium",
    UTM_TERM = "x-utm-term",
}

export const CLIENT_TYPES = {
    WEB: "web",
    WIDGET: "widget",
}

export const STRIPE_PAYMENT_METHOD_TYPES = {
    CARD: "card",
    US_BANK_ACCOUNT: "us_bank_account",
} as const

export const COMPONENT_IDS = {
    REPORT_LISTING: "report-listing",
} as const

export const QUERY_STRING_PROPS = {
    PACKAGE_ID: "package_id",
    QUOTE_ID: "quote_id",
    QUOTE_PACKAGE_ID: "quote_package_id",
    BOOKING_INVITE_ID: "booking_invite_id",
    INTERNAL_SOURCE: "is",
    INTERNAL_SOURCE_ENTITY_ID: "isei",
    INTERNAL_SOURCE_ENTITY_TYPE: "iset",
    UTM_CAMPAIGN: "utm_campaign",
    UTM_CONTENT: "utm_content",
    UTM_MEDIUM: "utm_medium",
    UTM_SOURCE: "utm_source",
    UTM_TERM: "utm_term",
    ACTIVITY_ID: "activity_id",
    INVITATION_ID: "invitation_id",
    PENDING_PAYMENT_ID: "pendingPaymentId",
    IS_LEGACY: "is_legacy",
    ONBOARDING_ID: "onboarding_id",
}

export const UNKNOWN_ID = "UNKNOWN_ID"

export enum TypeName {
    Amenity = "Amenity",
    AddOn = "AddOn",
    QuoteAddOn = "QuoteAddOn",
    PricingPackage = "PricingPackage",
    GenericAvailabilityEntry = "GenericAvailabilityEntry",
    ListingVisitedEvent = "ListingVisitedEvent",
    BookingStartedEvent = "BookingStartedEvent",
    TeamMember = "TeamMember",
    User = "User",
    GoogleReview = "GoogleReview",
    Activity = "Activity",
    Reaction = "Reaction",
    OwnReactions = "OwnReactions",
    NewsfeedEvent = "NewsfeedEvent",
    Address = "Address",
    GenericEvent = "GenericEvent",
}

export const WIDGET_URL = `${process.env.GATSBY_WEB_HOST}/widget-content`
export const WIDGET_ATTRIBUTES_KEY = "watt"

export const MAX_WIDTH = "1400px"

export enum ProviderErrors {
    CurrentOutfitter = "useCurrentOutfitter must be used within a CurrentOutfitterProvider",
    MultiMediaDisplay = "useMultiMediaDisplay must be used within a MultiMediaDisplayProvider",
    CurrentListing = "useCurrentListing must be used within a CurrentListingProvider",
    CurrentBooking = "useCurrentBooking must be used within a CurrentBookingProvider",
    Search = "useSearch must be used within a SearchProvider",
}

export const RESOURCE_TYPE = {
    LISTING: "LISTING",
    LODGING: "LODGING",
}

export const GEO_URL = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"

export const STRIPE_PRICING_TABLE_SCRIPT_URL =
    "https://js.stripe.com/v3/pricing-table.js"

export enum SearchSuggestionItemTypes {
    Outfitter = "Outfitter",
    Listing = "Listing",
    Country = "Country",
    State = "State",
    Species = "Species",
    SearchHistory = "SearchHistory",
}

export const SUGGESTION_IMG_HEIGHT = "40px"

export const LOCAL_STORAGE_KEYS = {
    SEARCH: "search",
}

export const SEARCH_INPUT_SELECT_ID = "input-select-component"

export const ICON_URLS = {
    RECENT: "https://firebasestorage.googleapis.com/v0/b/mallard-bay-dev.appspot.com/o/icons%2Frecent.svg?alt=media",
}

export const GENERIC_EVENT_TYPES = {
    BOOKING_INVITE_VIEWED: "BOOKING_INVITE_VIEWED",
    PAYMENT_METHOD_SELECTED: "PAYMENT_METHOD_SELECTED",
} as const

export const INPUT_TYPES = {
    TEXT: "text",
    NUMBER: "number",
    EMAIL: "email",
    PASSWORD: "password",
    TEL: "tel",
    URL: "url",
    DATE: "date",
    TIME: "time",
    DATETIME_LOCAL: "datetime-local",
    MONTH: "month",
    WEEK: "week",
    SEARCH: "search",
    COLOR: "color",
}
