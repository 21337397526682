import queryString, { type ParsedQuery } from "query-string"
import { navigate } from "gatsby-link"

import { QUERY_STRING_PROPS, UNKNOWN_ID } from "~config/constants"
import {
    isServerSide,
    parseInteger,
    dollarsToCents,
    getCurrentPath,
} from "~utils/helpers"
import { BookingInternalSource } from "~graphql/generated/graphql"
import type { SearchListingsForSportsmenInput } from "~graphql/generated/graphql"

import { removeUndefinedProps } from "./object-helpers"

const UNDEF_STRING_VALUE = "undefined"

export function getUrlQueryParamsObj() {
    const search = getUrlQueryParamsString()
    return queryString.parse(search)
}

export function getUrlQueryParamsString() {
    if (isServerSide()) {
        return ""
    }
    const { search } = window.location

    return search
}

export function getUrlQueryParam(paramName: string) {
    const paramsObj = getUrlQueryParamsObj()
    const param = paramsObj[paramName]
    const isParamValid =
        typeof param === "string" && param !== UNDEF_STRING_VALUE

    return isParamValid ? param : null
}

export function clearUrlQueryParams() {
    const pathname = getCurrentPath()

    return navigate(pathname)
}

export function getActivityIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.ACTIVITY_ID)
}

export function getQuoteIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.QUOTE_ID)
}

export function getQuotePackageIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.QUOTE_PACKAGE_ID)
}

export function getPricingPackageIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.PACKAGE_ID)
}

export function getBookingInviteIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.BOOKING_INVITE_ID)
}

export function getPendingPaymentIdUrlQueryParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.PENDING_PAYMENT_ID)
}

export function getIsLegacyQueryParam() {
    const param = getUrlQueryParam(QUERY_STRING_PROPS.IS_LEGACY)

    return param === "true"
}

export function getOnboardingIdParam() {
    return getUrlQueryParam(QUERY_STRING_PROPS.ONBOARDING_ID)
}

export function getUtmQueryParams() {
    const utmCampaign =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_CAMPAIGN) ?? undefined
    const utmContent =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_CONTENT) ?? undefined
    const utmMedium =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_MEDIUM) ?? undefined
    const utmSource =
        getUrlQueryParam(QUERY_STRING_PROPS.UTM_SOURCE) ?? undefined
    const utmTerm = getUrlQueryParam(QUERY_STRING_PROPS.UTM_TERM) ?? undefined

    return {
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        utmTerm,
    }
}

export function getInternalSourceQueryParams({
    defaultSource,
    defaultEntityId,
    defaultEntityType,
}: {
    defaultSource?: string
    defaultEntityId?: string
    defaultEntityType: string
}) {
    const internalSource = getUrlQueryParam(QUERY_STRING_PROPS.INTERNAL_SOURCE)
    const internalSourceEntityId = getUrlQueryParam(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_ID
    )
    const internalSourceEntityType = getUrlQueryParam(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_TYPE
    )

    // Only use the defaultEntityType if there is a defaultEntityId.
    // This will avoid accidentally setting the wrong entity type if for some reason it were missing even when the id is present
    const normalizedDefaultEntityType = defaultEntityId
        ? defaultEntityType
        : undefined

    const params = {
        // Default to an unprompted mallard bay booking (can also be from a widget or sent quote)
        internal_source:
            internalSource ?? defaultSource ?? BookingInternalSource.MallardBay,
        internal_source_entity_id:
            internalSourceEntityId ?? (defaultEntityId || UNKNOWN_ID),
        internal_source_entity_type:
            internalSourceEntityType ?? normalizedDefaultEntityType,
    }
    return removeUndefinedProps(params)
}

// Returns the text search as 'text' for resolver inputs
export function useSearchInputFromUrl() {
    const searchParams = getUrlQueryParamsObj()

    return mapSearchParamsToSearchInput(searchParams)
}

// Returns the text search as 'text' for resolver inputs
export function getSearchInputFromUrlQueryString(qString: string) {
    const params = queryString.parse(qString)

    return mapSearchParamsToSearchInput(params)
}

function mapSearchParamsToSearchInput(searchParams: ParsedQuery<string>) {
    const { query, ...rest } = mapSearchQueryObjectToSearchParams(searchParams)

    return {
        ...rest,
        text: query,
    }
}

function mapSearchQueryObjectToSearchParams(
    searchParams: ParsedQuery<string>
): SearchQueryParams {
    const {
        query,
        species,
        type,
        startDate,
        endDate,
        priceMin,
        priceMax,
        amenities,
        state,
        country,
        isFeatured,
    } = searchParams

    const priceMinNumber = getSingleNumericValue(priceMin)
    const priceMaxNumber = getSingleNumericValue(priceMax)

    const searchQueryParams: SearchQueryParams = {
        query: getSingleStringValue(query) || undefined,
        startDate: getSingleNumericValue(startDate) || undefined,
        endDate: getSingleNumericValue(endDate) || undefined,
        priceMin: priceMinNumber ? dollarsToCents(priceMinNumber) : undefined,
        priceMax: priceMaxNumber ? dollarsToCents(priceMaxNumber) : undefined,
        type: getSingleStringValue(type) || undefined,
        species: getArrayStringValues(species),
        amenities: getArrayStringValues(amenities),
        state: getSingleStringValue(state) || undefined,
        country: getSingleStringValue(country) || undefined,
        isFeatured: getSingleBooleanValue(isFeatured) || undefined,
    }

    return removeUndefinedProps(searchQueryParams)
}

// Returns the text search as 'query' for the query string name (to be consistent with the how it has been in the past)
export function getSearchQueryParams(searchString: string) {
    const params = queryString.parse(searchString)

    return mapSearchQueryObjectToSearchParams(params)
}

function getSingularQueryValue(value?: QueryParam) {
    return Array.isArray(value) ? value[0] : value
}

function getSingleStringValue(value?: QueryParam) {
    return getSingularQueryValue(value) ?? ""
}

function getSingleBooleanValue(value?: QueryParam) {
    return Boolean(getSingularQueryValue(value))
}

function getArrayStringValues(value?: QueryParam) {
    // We comma delimit rather than redeclare the query param so we still get a 'singular' value
    const stringValue = getSingleStringValue(value)

    if (!stringValue) return undefined

    return stringValue.split(",")
}

function getSingleNumericValue(value?: QueryParam) {
    const singleValue = getSingleStringValue(value)
    const numberValue = parseInteger(singleValue)

    return isFinite(numberValue) ? numberValue : undefined
}

type QueryParam = string | (string | null)[] | null | undefined

export type SearchQueryParams = Omit<
    SearchListingsForSportsmenInput,
    "text"
> & {
    query?: string
}
