import { componentStyles } from "@mallardbay/lib-react-components"
import React, { useState, useEffect } from "react"

import StripePricingTable from "~components/pages/pricing/stripe-pricing-table"
import LoadingOrErrorContainer from "~components/shared/todo-lib-react-components/loading-or-error-container"
import MbForGuidesLogo from "~components/shared/logo/mb-for-guides-logo"
import { VerticalStack } from "~components/shared/todo-lib-react-components/vertical-stack"
import { useColorModeValue } from "~utils/use-color-mode"
import useCreateDraftOnboarding from "~utils/useCreateDraftOnboarding"

export default function Pricing({
    shouldShowLogo = true,
}: {
    readonly shouldShowLogo?: boolean
}) {
    const [onboardingId, setOnboardingId] = useState<string | null>(null)

    const styles = useStyles()

    const { createDraftOnboarding, isLoading, error } =
        useCreateDraftOnboarding({ onSuccess: setOnboardingId })

    const pricingTableId = useColorModeValue(
        process.env.GATSBY_PRICING_TABLE_ID_LIGHT,
        process.env.GATSBY_PRICING_TABLE_ID_DARK ?? ""
    )
    const shouldRenderTable = !error && Boolean(onboardingId)

    // Create draft onboarding on mount.
    useEffect(() => {
        void createDraftOnboarding()
    }, [])

    return (
        <VerticalStack style={styles.root}>
            {shouldShowLogo && <MbForGuidesLogo />}
            <LoadingOrErrorContainer
                isLoading={isLoading}
                error={error}
                shouldRenderChildren={shouldRenderTable}
            >
                <StripePricingTable
                    pricingTableId={pricingTableId}
                    clientReferenceId={onboardingId ?? ""}
                />
            </LoadingOrErrorContainer>
        </VerticalStack>
    )
}

export function PricingNoLogo() {
    return <Pricing shouldShowLogo={false} />
}

function useStyles() {
    return componentStyles({
        root: {
            paddingTop: 6,
            gap: 8,
            alignItems: "center",
        },
    })
}
