import React from "react"
import { componentStyles } from "@mallardbay/lib-react-components"

import LogoDark from "~images/logos/mb-for-guides-logo-dark.png"
import LogoLight from "~images/logos/mb-for-guides-logo-light.png"
import LightAndDarkModeImage from "~components/shared/light-and-dark-mode-image"
import COPY from "~config/copy-constants"

export default function MbForGuidesLogo() {
    const styles = useStyles()

    return (
        <LightAndDarkModeImage
            style={styles.root}
            lightModeSrc={LogoLight}
            darkModeSrc={LogoDark}
            alt={COPY.MALLARD_BAY_LOGO}
        />
    )
}

function useStyles() {
    return componentStyles({
        root: { width: 300 },
    })
}
