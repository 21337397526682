import { useState, useEffect } from "react"
import { makeVar } from "@apollo/client"

const loadedScriptUrlsVar = makeVar<string[]>([])

export default function useScript(url: string) {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (loadedScriptUrlsVar().includes(url)) {
            setIsLoaded(true)

            return
        }

        loadScript({ url, setIsLoaded })
    }, [url])

    return { isLoaded }
}

function loadScript({
    url,
    setIsLoaded,
}: {
    url: string
    setIsLoaded: (isLoaded: boolean) => void
}) {
    const script = document.createElement("script")
    script.src = url

    script.addEventListener("load", () => {
        const existingUrlsLoaded = loadedScriptUrlsVar()

        loadedScriptUrlsVar([...existingUrlsLoaded, url])
        setIsLoaded(true)
    })
    document.body.appendChild(script)
}
