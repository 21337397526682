import { useIsDarkMode } from "@mallardbay/lib-react-components"

export const useColorModeValue = (
    lightValue: string | undefined,
    darkValue: string
) => {
    const isDarkMode = useIsDarkMode()

    if (isDarkMode) return darkValue
    return lightValue
}
